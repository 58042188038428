// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

// Custom
.select2-container .select2-selection--single {
    height: calc(2.25rem + 2px)!important;
}
.select2-container--default .select2-selection--single {
    background-color: #f8fafc!important;
}
.select2-selection__arrow {
    top: 6px!important;
    right: 6px!important;
}
.custom-clear-button {
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    top: 8px;
    right: 27px;
    display: none;
}
.custom-blink {
    animation: blinker 1s linear infinite;
}  
@keyframes blinker {
    50% {
      opacity: 0;
    }
}